import { graphql, Link } from "gatsby"
import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../../components/Layout"
import Img from "gatsby-image"
import { useFlexSearch } from "react-use-flexsearch";
import * as queryString from "query-string";
import RenderTags from "../../components/RenderTags";
import kebabCase from "lodash/kebabCase"
import SEO from "../../components/SEO";

const SearchBlogs = ({ blogs, localSearchBlogs, location }) => {
    const { search } = queryString.parse(location.search)
    const [query, setQuery] = useState(search || "")

    const results = useFlexSearch(
        query, 
        localSearchBlogs.index, 
        localSearchBlogs.store
    )

    function disableSearchButton () {
        document.querySelector(".app-section-blogs .app-blogs-detail-search .app-blogs-detail-icon-close").style.display = "block";
        var check = document.querySelector(".app-section-blogs .app-blogs-detail-search .app-blogs-detail-search-input");
        if (check.value.length === 0) {
            document.querySelector(".app-section-blogs .app-blogs-detail-search .app-blogs-detail-search-btn").style.display = "block";
            document.querySelector(".app-section-blogs .app-blogs-detail-search .app-blogs-detail-icon-close").style.display = "none";
        } else {
            document.querySelector(".app-section-blogs .app-blogs-detail-search .app-blogs-detail-search-btn").style.display = "none";
        }
    }

    function clearDataSearch() {
        document.querySelector(".app-section-blogs .app-blogs-detail-search .app-blogs-detail-search-input").value = "";
        document.querySelector(".app-section-blogs .app-blogs-detail-search .app-blogs-detail-search-btn").style.display = "block";
        document.querySelector(".app-section-blogs .app-blogs-detail-search .app-blogs-detail-icon-close").style.display = "none";
    }
  
    return (
      <>
        <div className="app-blogs-detail-search">
            <input 
                id="search"
                className="app-blogs-detail-search-input" 
                placeholder="Ask a question" 
                value={query}
                onChange={e => {
                    setQuery(e.target.value)
                    disableSearchButton()
                }}
            />
            <input className="app-blogs-detail-search-btn" type="button" value="Search" />
            <img className="app-blogs-detail-icon-search" src="/app-icon-search.svg" alt="app icon search"/>
            <input 
                className="app-blogs-detail-icon-close" 
                type="button" 
                onClick={e => {
                    setQuery(e.target.value)
                    clearDataSearch()
                }}
            />
            {query ? (
                results.length > 0 ? (
                    <div className="app-blogs-search-results">
                        <h6>Showing {results.length} results</h6>
                        <BlogsKeyworkSearch results={results} />
                        {/* <h6 className="app-blogs-detail-keyword-search-show-more">
                            Show more
                        </h6> */}
                    </div>
                ) : (
                    <h6 className="app-blogs-detail-keyword-search-no-results">
                        Sorry, couldn't find any posts matching this
                        search.
                    </h6>
                )
            ) : (
                <BlogsAllPost blogs={blogs} />
            )}
        </div>
       </>
    )
}

const BlogsKeyworkSearch = ({ results }) => 
    results.map(node => {
        const title = node.title || node.slug
        const author = node.author
        const publishedAt = node.publishedAt
        const description = node.description
        const tags = node.tags
        const thumb = node.thumb

        return (
            <div className="app-blogs-detail-keyword-search" key={node.id}>
                <Row className="app-blogs-detail-keyword-search-row-custom">
                    <Col xs={12} md={5} lg={5} xl={3} className="app-blogs-detail-keyword-search-col-custom">
                        <div className="app-blogs-detail-keyword-search-left">
                            <Link to="/support/blogs/the-first-blog">
                                <img src={ thumb.childImageSharp.fluid.src } alt="blogs detail topic search img" />
                            </Link>
                        </div>
                    </Col>
                    <Col xs={12} md={7} lg={7} xl={9} className="app-blogs-detail-keyword-search-col-custom">
                        <div className="app-blogs-detail-keyword-search-right">
                            <Link to="/support/blogs/the-first-blog">
                                {title}
                            </Link>
                            <h6>{description}</h6>
                            <div className="app-blogs-keyword-search-infor-author-calendar-related-article">
                                <div className="app-blogs-keyword-search-infor-author-calendar">
                                    <div className="app-blogs-keyword-search-right-author">
                                        <img src="/app-icon-user.svg" alt="app icon user"/>
                                        <h6>{author}</h6>
                                    </div>
                                    <div className="app-blogs-keyword-search-right-calendar">
                                        <img src="/app-icon-calendar.svg" alt="app icon calendar"/>
                                        <h6>{publishedAt}</h6>
                                    </div>
                                </div>
                                {/* <div className="app-blogs-keyword-search-related-article">
                                    <img src="/app-blogs-topic-search-right-tag.svg" alt="app blogs topic search right tag" />
                                    <div className="app-blogs-keyword-search-related-article-tag">
                                        {(tags).map((res) => (
                                            <Link className="app-blogs-keyword-search-related-article-link" to={`/tags/${kebabCase(res)}/`} key={res}>{res}</Link>
                                        ))}
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
)

const BlogsAllPost = ({ blogs }) => {
    return (
        <div className="app-blogs-detail-all-posts">
            <Row>
                {blogs.map((res) => (
                    <Col xs={12} md={12} lg={6} key={res.id}>
                        <div className="app-blogs-detail-post-content">
                            <Link to={"/support/blogs/" + res.frontmatter.slug}>
                                <Img
                                    fluid={
                                        res.frontmatter
                                            .thumb
                                            .childImageSharp
                                            .fluid
                                    }
                                />
                            </Link>
                            <div className="app-blogs-detail-post-content-description">
                                <Link to={"/support/blogs/" + res.frontmatter.slug}>
                                    {res.frontmatter.title}
                                </Link>
                                <h6>{res.frontmatter.description}</h6>
                                <div className="app-blogs-detail-post-content-author-calendar">
                                    <div className="app-blogs-detail-post-content-author">
                                        <img src="/app-icon-user.svg" alt="app icon user"/>
                                        <h6>{res.frontmatter.author}</h6>
                                    </div>
                                    <div className="app-blogs-detail-post-content-calendar">
                                        <img src="/app-icon-calendar.svg" alt="app icon calendar"/>
                                        <h6>{res.frontmatter.publishedAt}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
            {/* <div className="app-blogs-detail-post-banner">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod 
                tempor incididunt ut labore et dolore. Ed do eiusmod tempor incididunt ut 
                labore et dolore.
            </div> */}
        </div>
    )
}

class Blogs extends React.Component {
    render() {
        const { data, location } = this.props;
        const localSearchBlogs = data.localSearchBlogs;
        const localSearchBlogsvi = data.localSearchBlogsvi;
        const { html } = data.markdownRemark;
        const blogs_introduction_vi = data.blogs_introduction_vi.nodes;
        const blogs = data.allMarkdownRemark.nodes;
        const blogs_vi = data.blogs_all_posts_vi.nodes;
        const { group } = data.allMarkdownRemark;
        const group_vi = data.blogs_all_posts_vi.group_vi;

        return (
            <Layout>
                <SEO title="Blogs" description="Appvity Blogs for everyone to get more infomation about eProducts and Microsoft. Blogs are created from the Appvity team engineer for best precision and useful tips/hints for users." meta={[{ name: `keywords`, content: ["Appvity blogs", "tips for eProducts", "tips for eTask", "tips for eHelpdesk"]}]} pathname="/support/blogs" />
                <div className="main-content-padding">
                    <div className="app-general-seciton app-section-blogs-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={5}>
                                    <div 
                                        className="app-data-en app-section-blogs-introduction-detail" 
                                        dangerouslySetInnerHTML={{ __html: html }}
                                    />
                                    {blogs_introduction_vi.map((res) => (
                                        <div className="app-data-vi app-section-blogs-introduction-detail" key={res.id} dangerouslySetInnerHTML={{ __html: res.html }} />
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={7}></Col>
                            </Row>
                        </Container>
                    </div>
                    <div className="app-general-seciton app-section-blogs" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                        <Container>
                            <Row>
                                <Col xs={12} md={12} lg={1}></Col>
                                <Col xs={12} md={12} lg={9}>
                                    <div className="app-data-en">
                                        <SearchBlogs 
                                            localSearchBlogs={localSearchBlogs}
                                            location={location}
                                            blogs={blogs}
                                        />
                                    </div>
                                    <div className="app-data-vi">
                                        <SearchBlogs 
                                            localSearchBlogs={localSearchBlogsvi}
                                            location={location}
                                            blogs={blogs_vi}
                                        />
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={2}>
                                    <div className="app-data-en">
                                        <RenderTags group={group} />
                                    </div>
                                    <div className="app-data-vi">
                                        <RenderTags group={group_vi} />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    {/* <div className="banner-page">
                        <Container>
                            <h1>Blogs</h1>
                            <h2>Main content blogs</h2>
                        </Container>
                    </div> */}
                    {/* <div className="blog-list">
                        <Container>
                            <Row>
                                {blogs.map((res) => (
                                    <Col xs={12} md={4}>
                                        <div className="blog-item">
                                            <div className="thumb">
                                                <Link
                                                    to={
                                                        "/support/blogs/" +
                                                        res.frontmatter.slug
                                                    }
                                                    key={res.id}
                                                >
                                                    <Img
                                                        fluid={
                                                            res.frontmatter
                                                                .thumb
                                                                .childImageSharp
                                                                .fluid
                                                        }
                                                    />
                                                </Link>
                                            </div>
                                            <h3>
                                                <Link
                                                    to={
                                                        "/support/blogs/" +
                                                        res.frontmatter.slug
                                                    }
                                                    key={res.id}
                                                >
                                                    {res.frontmatter.title}
                                                </Link>
                                            </h3>
                                            <h4>{res.frontmatter.subTitle}</h4>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Container>
                    </div> */}
                </div>
            </Layout>
        )
    }
}

export default Blogs

export const query = graphql`
    query BlogsPage {
        allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "blogs_all_posts" } }, fields: { draft: { eq: false } } }
            sort: { fields: frontmatter___publishedAt, order: DESC }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    slug
                    author
                    publishedAt(formatString: "MMM Do, YYYY")
                    description
                    thumb {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                id
            }
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
        blogs_all_posts_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "blogs_all_posts_vi" } }, fields: { draft: { eq: false } } }
            sort: { fields: frontmatter___publishedAt, order: DESC }
        ) {
            nodes {
                html
                frontmatter {
                    title
                    slug
                    author
                    publishedAt(formatString: "MMM Do, YYYY")
                    description
                    thumb {
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
                id
            }
            group_vi: group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
        markdownRemark(
            frontmatter: { directory: { eq: "blogs_introduction" } }
        ) {
            html
            id
        }
        blogs_introduction_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "blogs_introduction_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        localSearchBlogs {
            index
            store
        }
        localSearchBlogs {
            publicIndexURL
            publicStoreURL
        }
        localSearchBlogsvi {
            index
            store
        }
        localSearchBlogsvi {
            publicIndexURL
            publicStoreURL
        }
    }
`
